@tailwind base;
@tailwind components;
@tailwind utilities;
.bg-icon-position {
  background-image: url("./Assets/icons/Icons.svg"); /* Use the correct path based on your public folder structure */
  background-position: -32px -64px; /* Adjust the position based on your sprite */
  background-size: cover;
}
.spl.heart-stroke-white {
  background-position: -72px 0;
}
.sprite {
  background-image: url(./Assets/icons//Icons.svg);
}
*{
  -webkit-tap-highlight-color: transparent;
}